window.addEventListener('load', () => {
    burgerAction();
    // scrollToTop();
    dropdownMenu();
    openPopup();
    // closePopup();
});

window.addEventListener('resize', () => {
});
  
const body = document.querySelector('body');

function dropdownMenu() {
    const menuElements = body.querySelectorAll('.header-wrapper .nav-menu-wrapper .menu-item.menu-item-has-children');
    const menuElementsMobile = body.querySelectorAll('.header-wrapper .mobile-menu-wrapper .menu-item.menu-item-has-children');

    menuElements.forEach(element => {
        const curet = element.querySelector('.caret');
        const button = element.querySelector('.button-link');
        const link = element.querySelector('a');

        if (button) {
            button.addEventListener('mouseover', (e)=>{
                e.preventDefault();
    
                menuElements.forEach(elementSecond => {
                    elementSecond.classList.remove('open');                    
                });
                element.classList.add('open');
            });

            button.addEventListener('click', (e)=>{
                e.preventDefault();
    
                menuElements.forEach(elementSecond => {
                    if (element != elementSecond) {
                        elementSecond.classList.remove('open');                    
                    }
                });
                element.classList.toggle('open');
            });
        }
        else {
            curet.addEventListener('mouseover', (e)=>{
                e.preventDefault();
    
                menuElements.forEach(elementSecond => {
                    elementSecond.classList.remove('open');                    
                });
                element.classList.add('open');
            });

            link.addEventListener('mouseover', (e)=>{
                e.preventDefault();
    
                menuElements.forEach(elementSecond => {
                    elementSecond.classList.remove('open');                    
                });
                element.classList.add('open');
            });
    
            curet.addEventListener('click', (e)=>{
                e.preventDefault();
    
                menuElements.forEach(elementSecond => {
                    if (element != elementSecond) {
                        elementSecond.classList.remove('open');                    
                    }
                });            
                element.classList.toggle('open');
            });    
        }
    });

    menuElementsMobile.forEach(element => {
        const curet = element.querySelector('.caret');

        curet.addEventListener('click', (e)=>{
            e.preventDefault();

            menuElementsMobile.forEach(elementSecond => {
                if (element != elementSecond) {
                    elementSecond.classList.remove('open');                    
                }
            });

            element.classList.toggle('open');
        });
    });
}

function burgerAction() {
    const burger = body.querySelector('.js-burger');
    const menu = body.querySelector('.mobile-menu-wrapper');

    burger.addEventListener('click', ()=>{
        burger.classList.toggle('open');
        menu.classList.toggle('open');
        body.classList.toggle('no-scroll');
    });
}

function openPopup() {
    const links = body.querySelectorAll('a');
    const buttons = body.querySelectorAll('.js-open-form');

    links.forEach((link) => {
        const href = link.href;
        if (href.includes('?form_id')) {
            link.addEventListener('click', (e)=>{
                e.preventDefault();
                const queryString = href.split('?')[1].split('=');
                const formID = queryString[1];
                const form = body.querySelector('.js-popup-' + formID);
                body.classList.add('no-scroll');
                form.classList.add('open');
                const course = link.dataset.course;
                if (course) {
                    const fieldsID = link.dataset.field;
                    const input = form.querySelector("#"+fieldsID);
                    input.value = course;
                }
                closePopup();
            })
        }
    });

    buttons.forEach((button) => {
        const formID = button.dataset.id;
        if (formID) {
            button.addEventListener('click', ()=>{
                const form = body.querySelector('.js-popup-' + formID);
                body.classList.add('no-scroll');
                form.classList.add('open');
                const course = button.dataset.course;
                if (course) {
                    const fieldsID = button.dataset.field;
                    const input = form.querySelector("#"+fieldsID);
                    input.value = course;
                }
                closePopup();
            });
        }
    });
}

function closePopup() {
    const popups = body.querySelectorAll('.popup-wrapper.open');

    popups.forEach(popup => {
        const close = popup.querySelector('.close-popup');

        close.addEventListener('click', ()=>{
            popup.classList.remove('open');
            body.classList.remove('no-scroll');
        });

        popup.addEventListener('click', (e)=> {
            const target = e.target;

            if (target === popup) {
                popup.classList.remove('open');
                body.classList.remove('no-scroll');                        
            }
        })
    });
}

// function video() {
//     const videos = body.querySelectorAll('.video.file');

//     videos.forEach(element => {
//         if (element) {
//             const video = element.querySelector('video');
//             const button = element.querySelector('.button-play');
//             video.currentTime = 1;

//             element.addEventListener('click', (e)=> {
//                 const target = e.target;
                
//                 if (target != button) {
//                     element.classList.remove('play');
//                     video.pause();
//                 }
//             });

//             button.addEventListener('click', ()=> {
//                 element.classList.add('play');
//                 video.play();
//             });
//         }
//     });
// }